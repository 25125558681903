function apenasNumeros(value:string) {
    let valor = "";
    if (value !== "" && value != null && value != undefined) {
        valor = value.toString().replace(/[^0-9]/g, "");
    }
    return valor;
}

function cpfMask(value:string) {
    if (!value) {
        return "";
    }
    return value
        .replace(/\D/g, "")
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d{1,2})/, "$1-$2")
        .replace(/(-\d{2})\d+?$/, "$1");
}

function cnpjMask(value:string) {
    if (!value) {
        return "";
    }
    return value
        .replace(/\D/g, "")
        .replace(/(\d{2})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d)/, "$1/$2")
        .replace(/(\d{4})(\d)/, "$1/$2")
        .replace(/(-\d{2})\d+?$/, "$1");
}

function celularMask(value:string) {
    if ((value || false) && value.substr(0, 1) !== "+") {
        const onlyDigits = value.replace(/[^0-9]/g, "");
        let searchPrimeiroSegmento = /(\d{4})(\d)/;
        if (onlyDigits.length > 10) {
            searchPrimeiroSegmento = /(\d{5})(\d)/;
        }
        if (onlyDigits.length == 12) {
            let regexNumeroBrasilCom55 = /(\d{2})(\d{2})(\d{4})(\d{4})/;
            let resultado = regexNumeroBrasilCom55.test(onlyDigits);
            if (resultado) {
                return onlyDigits
                    .replace(/\D/g, "")
                    .replace(/(\d{2})(\d{2})(\d{4})(\d{4})/, "+$1 ($2) $3-$4");
            }
        }

        if (onlyDigits.length == 13) {
            let regexNumeroBrasilCom55 = /(\d[55])(\d{2})(\d{5})(\d{4})/;
            let resultado = regexNumeroBrasilCom55.test(onlyDigits);
            if (resultado) {
                return onlyDigits
                    .replace(/\D/g, "")
                    .replace(/(\d{2})(\d{2})(\d{5})(\d{4})/, "+$1 ($2) $3-$4");
            } else {
                return `+${onlyDigits}`;
            }
        }
        return (
            onlyDigits
                .replace(/\D/g, "")
                .replace(/(\d{2})(\d)/, "($1) $2")
                .replace(searchPrimeiroSegmento, "$1-$2")
                //.replace(/(\d{4})-(\d)(\d{4})/, '$1$2-$3')
                .replace(/(-\d{4})\d+?$/, "$1")
        );
    } else if ((value || false) && value.substr(0, 1) === "+") {
        const onlyDigits = value.replace(/[^0-9]/g, "");
        if (!verificarDDIBrasileiro(onlyDigits)) {
            return `+${onlyDigits}`;
        }
        let searchSegundoSegmento = /(\d{4})(\d)/;
        if (onlyDigits.length > 10) {
            searchSegundoSegmento = /(\d{5})(\d)/;
        }
        return onlyDigits
            .replace(/\D/g, "")
            .replace(/(\d{2})(\d{2})(\d)/, "($2) $3")
            .replace(searchSegundoSegmento, "$1-$2")
            .replace(/(-\d{4})\d+?$/, "$1");
    }

    return null;
}

function horaMask(value:string) {
    if (!value) {
        return "";
    }
    return value
        .replace(/\D/g, "")
        .replace(/(\d{2})(\d)/, "$1:$2")
        .replace(/(:\d{2})\d+?$/, "$1");
}

function cepMask(value:string) {
    if (!value) {
        return "";
    }
    return value
        .replace(/\D/g, "")
        .replace(/(\d{5})(\d)/, "$1-$2")
        .replace(/(-\d{3})\d+?$/, "$1");
}

function pisMask(value:string) {
    if (!value) {
        return "";
    }
    return value
        .replace(/\D/g, "")
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{5})(\d)/, "$1.$2")
        .replace(/(\d{5}\.)(\d{2})(\d)/, "$1$2-$3")
        .replace(/(-\d{1})\d+?$/, "$1");
}

function identidadeMask(value:string) {
    return value
        .replace(/\D/g, "")
        .replace(/(\d{2})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d)/, "$1-$2")
        .replace(/(-\d{1})\d+?$/, "$1");
}

function realMoeda(value:string) {
    if (value != "" && value != null && value != undefined) {
        let str = String(value);

        let retorno = str.replace(/\D/g, "").replace(/([0-9]{2})$/g, ",$1");

        if (retorno.length > 6)
            retorno = retorno.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");

        return retorno;
    } else {
        return value;
    }
}

const formatarParaMascaraMoeda = function (value:string|number) {
    if (value === undefined) {
        value = "0";
    }
    return Number(value).toFixed(2).toString().replace(".", ",");
};

const limpaMascaraMoeda = function (value:string) {
    if (value === undefined) {
        value = "0";
    }
    // return value;
    return value.replace("R$ ", "").replaceAll(".", "");
};

const formatarDeMascaraMoeda = function (strValue:string) {
    if (strValue || false) {
        return Number(strValue.toString().replace(/\./g, "").replace(",", "."));
    }

    return null;
};

const formatarDeMascaraController = function (strValue:string) {
    if (strValue || false) {
        let nvValor = strValue.replace(/\D/g, "");
        nvValor = nvValor.replace(/([0-9]{2})$/g, ".$1");

        return Number(nvValor);
    }

    return null;
};

const validaCPF = function (strCPF:string) {
    if (strCPF) {
        strCPF = strCPF.replace(/\D/g, "");
        let soma = 0;
        let resto;
        if (strCPF == "00000000000") return false;

        for (let i = 1; i <= 9; i++)
            soma = soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
        resto = (soma * 10) % 11;

        if (resto == 10 || resto == 11) resto = 0;
        if (resto != parseInt(strCPF.substring(9, 10))) return false;

        soma = 0;
        for (let i = 1; i <= 10; i++)
            soma = soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
        resto = (soma * 10) % 11;

        if (resto == 10 || resto == 11) resto = 0;
        if (resto != parseInt(strCPF.substring(10, 11))) return false;
        return true;
    } else {
        return false;
    }
};

const validaHorario = function (value:string) {
    return /^([0-1][0-9]|2[0-3]):([0-5][0-9])$/.test(value);
};

const verificarDDIBrasileiro = function (celular:string) {
    let telefone = String(celular);

    return (
        telefone.substring(0, 3) == "+55" || telefone.substring(0, 2) == "55"
    );
};

export {
    cpfMask,
    cnpjMask,
    horaMask,
    celularMask,
    cepMask,
    pisMask,
    identidadeMask,
    realMoeda,
    apenasNumeros,
    formatarDeMascaraMoeda,
    formatarParaMascaraMoeda,
    validaCPF,
    validaHorario,
    limpaMascaraMoeda,
    formatarDeMascaraController,
};
