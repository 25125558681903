import { Route, Routes, useParams } from "react-router-dom";
import { QuestionarioPage } from "./pages/QuestionarioPage";
import { Autenticacao } from "./pages/Auth";

export const Routers = () => {

    return (
        <Routes>
            {/* <Route path="/:token" element={<Autenticacao />} /> */}
            <Route path="/" element={<QuestionarioPage />} />
        </Routes>
    )
}