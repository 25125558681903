import React from "react";
import "./index.css";
import checked from "../../assets/images/checked.png";

export function MensagemRespondida(){
    return(
        <div className="mensagem-respondido-section">
            <img src={checked}/>
            <span>Resposta Envida com sucesso!!!</span>
        </div>
    )
}