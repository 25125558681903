import React from "react";
import "./index.css"
import background from "../../assets/images/background.png";
import logo from "../../assets/images/logo.png";
export function Header() {
    return (
        <div className="header-section"
            style={{
                backgroundImage: `url(${background})`
            }}
        >
            <div className="empreendimento-section">
                <img className="logo-empreendimento" src={logo} alt="logo_empreendimento"/>
                <span className="nome-empreendimento">Marquises</span>
            </div>
        </div>
    )
}