import React, { useContext } from "react";
import "./index.css";
import { Header } from "../../components/Header";
import { Questionario } from "../../components/Questionario";
import { MensagemRespondida } from "../../components/MensagemRespondida";
import { QuestionarioContext } from "../../context/QuestionarioContext/QuestionarioContext";


export function QuestionarioPage() {
    const { questionarioRespondido } = useContext(QuestionarioContext);
    return (
        <>
            <Header />
            <div className="questionario-section">
                <div className="title-section">
                    <span className="title">PESQUISA VISITANTES - PERPLAN</span>
                    {/* <p className="mensagem">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> */}
                </div>
                <div className="body-section">
                    {questionarioRespondido ?
                        <MensagemRespondida />
                        :
                        <Questionario />
                    }
                </div>
            </div>
        </>
    )
}