export type QuestionarioType = {
    id_questionario: number;
    descricao: string;
    empreendimentos_vinculados?: any[];
    perguntas?: PerguntaQuestionarioType[];
    criado_por: number;
    oculto: number;
    ativo: string;
};

export type PerguntaQuestionarioType = {
    id_questionario: number;
    id_pergunta: number;
    pergunta: PerguntaType;
    oculto: number;
    criado_por: number;
    ativo: string;
};

export type RespostasQuestionarioType = {
    id_oportunidade: number;
    id_questionario: number;
    id_pergunta: number;
    resposta: [];
    criado_por: number;
    nome_atualizado_por: string;
    atualizado_em: string;
};

export type UltimaAtualizacaoQuestionarioType = {
    data: string;
    usuario: string;
};

export type PerguntaType = {
    id_pergunta: number;
    enunciado: string;
    id_tipo_resposta_pergunta: number;
    tipo_resposta_pergunta: TipoRespostaPerguntaType;
    options?: any[];
    criado_por: number;
    oculto: number;
    ativo: string;
};

export type TipoRespostaPerguntaType = {
    id_tipo_resposta_pergunta: number;
    nome: string;
};

export enum TipoRespostaEnum {
    TEXTO = 1,
    DATA = 2,
    CPF = 3,
    CEP = 4,
    TELEFONE = 5,
    MOEDA = 6,
    SIMPLES_SELECAO = 7,
    MULTIPLA_SELECAO = 8,
    SIMPLES_SELECAO_CAMPOS_DINAMICOS = 9,
    MULTIPLA_SELECAO_CAMPOS_DINAMICOS = 10,
    CHECKBOX = 11,
}
