import React, { createContext, Dispatch, ReactElement, useState } from "react";
import { QuestionarioType } from "../../components/types/questionario";

interface QuestionarioContextProps {
  questionario: QuestionarioType;
  setQuestionario: Dispatch<React.SetStateAction<QuestionarioType>>;
  questionarioRespondido: boolean;
  setQuestionarioRespondido: Dispatch<React.SetStateAction<boolean>>;
}

export const QuestionarioContext = createContext({} as QuestionarioContextProps);

export function QuestionarioProvider({ children }: { children: ReactElement }) {
  const [questionarioRespondido, setQuestionarioRespondido] = useState(false);
  const [questionario, setQuestionario] = useState<QuestionarioType>(
    {
      "id_questionario": 1,
      "descricao": "PESQUISA VISITANTES - PERPLAN",
      "empreendimentos_vinculados": [
        {
          "label": "ANÁPOLIS",
          "value": "53"
        },
        {
          "label": "ARARAQUARA",
          "value": "48"
        },
        {
          "label": "BOTUCATU",
          "value": "62"
        },
        {
          "label": "BREVES LANÇAMENTOS",
          "value": "38"
        },
        {
          "label": "CAMPINAS",
          "value": "68"
        },
        {
          "label": "CAMPO GRANDE",
          "value": "51"
        },
        {
          "label": "CAP - NS",
          "value": "45"
        },
        {
          "label": "CIDADE JARDIM",
          "value": "34"
        },
        {
          "label": "CIVITAS",
          "value": "26"
        },
        {
          "label": "DEODORO",
          "value": "7"
        },
        {
          "label": "DRAUZIO",
          "value": "65"
        },
        {
          "label": "ED.CHILE",
          "value": "70"
        },
        {
          "label": "EMPREENDIMENTO MODELO",
          "value": "1"
        },
        {
          "label": "FLORIANO1680",
          "value": "6"
        },
        {
          "label": "FRANCA",
          "value": "49"
        },
        {
          "label": "GRAND RAYA",
          "value": "23"
        },
        {
          "label": "GRANDVERSE",
          "value": "13"
        },
        {
          "label": "GRANDVERSE GARDEN",
          "value": "73"
        },
        {
          "label": "GRANDVERSE PLACE",
          "value": "2"
        },
        {
          "label": "HOMEPAGE",
          "value": "60"
        },
        {
          "label": "HYPE",
          "value": "8"
        },
        {
          "label": "INDAIATUBA",
          "value": "50"
        },
        {
          "label": "JARDIM SUL",
          "value": "21"
        },
        {
          "label": "LIBERDADE 1226",
          "value": "14"
        },
        {
          "label": "LÍDICE",
          "value": "3"
        },
        {
          "label": "LOTE 5",
          "value": "69"
        },
        {
          "label": "MAGNÓLIAS",
          "value": "28"
        },
        {
          "label": "MANACÁS",
          "value": "27"
        },
        {
          "label": "MARIÊ160",
          "value": "12"
        },
        {
          "label": "MARQUISES",
          "value": "9"
        },
        {
          "label": "NÃO ESPECIFICO",
          "value": "59"
        },
        {
          "label": "NATURE",
          "value": "4"
        },
        {
          "label": "NOVA ALIANÇA",
          "value": "61"
        },
        {
          "label": "NP870",
          "value": "40"
        },
        {
          "label": "PARAGON",
          "value": "54"
        },
        {
          "label": "PARQUE DOS RESEDÁS",
          "value": "17"
        },
        {
          "label": "PARQUE MANACÁS",
          "value": "37"
        },
        {
          "label": "PIRACICABA",
          "value": "55"
        },
        {
          "label": "PORTAL DA MATA",
          "value": "20"
        },
        {
          "label": "PRESIDENTE PRUDENTE",
          "value": "44"
        },
        {
          "label": "QUINTA DO MONTE",
          "value": "35"
        },
        {
          "label": "RESIDENCIAL DO BOSQUE",
          "value": "33"
        },
        {
          "label": "RIBEIRÃO PRETO",
          "value": "52"
        },
        {
          "label": "RIO VERDE",
          "value": "64"
        },
        {
          "label": "SÃO PAULO",
          "value": "66"
        },
        {
          "label": "SEIVA",
          "value": "5"
        },
        {
          "label": "SEM EMPREENDIMENTO",
          "value": "39"
        },
        {
          "label": "SKY",
          "value": "30"
        },
        {
          "label": "TERRAÇO D'ITÁLIA",
          "value": "41"
        },
        {
          "label": "TESTE PRODUTO",
          "value": "57"
        },
        {
          "label": "THZ 2965",
          "value": "10"
        },
        {
          "label": "UBER VAN DER ROHE",
          "value": "19"
        },
        {
          "label": "UBERLÂNDIA",
          "value": "46"
        },
        {
          "label": "UPPER RIBEIRÃO",
          "value": "43"
        },
        {
          "label": "URBAN",
          "value": "22"
        },
        {
          "label": "VAN DER ROHE",
          "value": "71"
        },
        {
          "label": "VARGEM GRANDE PAULISTA",
          "value": "67"
        },
        {
          "label": "VERDI BUTANTA",
          "value": "58"
        },
        {
          "label": "VILLA DI CAPRI",
          "value": "42"
        },
        {
          "label": "VILLA DI FRANCE",
          "value": "63"
        },
        {
          "label": "VILLA PIEMONTE",
          "value": "29"
        },
        {
          "label": "VILLA ROMANA",
          "value": "25"
        },
        {
          "label": "VILLA TOSCANA",
          "value": "32"
        },
        {
          "label": "VILLAGE MONET",
          "value": "24"
        },
        {
          "label": "VILLAGIO DI FIRENZE",
          "value": "31"
        },
        {
          "label": "VILLAS DO MIRANTE",
          "value": "11"
        },
        {
          "label": "VILLE DE FRANCE",
          "value": "36"
        },
        {
          "label": "VIVALEGRO",
          "value": "16"
        },
        {
          "label": "VIVENDAS DA MATA",
          "value": "18"
        },
        {
          "label": "VOLTA REDONDA",
          "value": "56"
        },
        {
          "label": "VOLTA REDONDA - INST.",
          "value": "15"
        },
        {
          "label": "VOTORANTIM",
          "value": "47"
        },
        {
          "label": "HELADE",
          "value": "76"
        }
      ],
      "perguntas": [
        {
          "id_questionario": 1,
          "id_pergunta": 1,
          "pergunta": {
            "id_pergunta": 1,
            "enunciado": "Quais ações contribuíram para que conhecesse o nosso produto? \n(assinale todas as alternativas necessárias)",
            "id_tipo_resposta_pergunta": 8,
            "tipo_resposta_pergunta": {
              "id_tipo_resposta_pergunta": 8,
              "nome": "Multipla seleção"
            },
            "options": [
              {
                "label": "AÇÃO DA MARCA PERPLAN",
                "value": "AÇÃO DA MARCA PERPLAN"
              },
              {
                "label": "FOLHETO",
                "value": "FOLHETO"
              },
              {
                "label": "CARRO DE SOM",
                "value": "CARRO DE SOM"
              },
              {
                "label": "DIVULGAÇÃO EM ESTABELECIMENTO COMERCIAL",
                "value": "DIVULGAÇÃO EM ESTABELECIMENTO COMERCIAL"
              },
              {
                "label": "RÁDIO",
                "value": "RÁDIO"
              },
              {
                "label": "TV",
                "value": "TV"
              },
              {
                "label": "REVISTA/ JORNAL",
                "value": "REVISTA/ JORNAL"
              },
              {
                "label": "OUTDOOR/ PLACA",
                "value": "OUTDOOR/ PLACA"
              },
              {
                "label": "PASSAGEM PELO ESTANDE",
                "value": "PASSAGEM PELO ESTANDE"
              },
              {
                "label": "GOOGLE",
                "value": "GOOGLE"
              },
              {
                "label": "REDES SOCIAIS",
                "value": "REDES SOCIAIS"
              },
              {
                "label": "SITE DA PERPLAN",
                "value": "SITE DA PERPLAN"
              },
              {
                "label": "PORTAL DE IMÓVEIS",
                "value": "PORTAL DE IMÓVEIS"
              },
              {
                "label": "NOTÍCIAS SOBRE A PERPLAN OU OUTROS SITES",
                "value": "NOTÍCIAS SOBRE A PERPLAN OU OUTROS SITES"
              },
              {
                "label": "INDICAÇÃO DE AMIGO/ PARENTE",
                "value": "INDICAÇÃO DE AMIGO/ PARENTE"
              },
              {
                "label": "CONTATO DE UM CORRETOR/ CARTEIRA CORRETOR",
                "value": "CONTATO DE UM CORRETOR/ CARTEIRA CORRETOR"
              }
            ],
            "criado_por": 1,
            "oculto": 0,
            "ativo": "1"
          },
          "criado_por": 1,
          "oculto": 0,
          "ativo": "1"
        },
        {
          "id_questionario": 1,
          "id_pergunta": 2,
          "pergunta": {
            "id_pergunta": 2,
            "enunciado": "Você já conhecia a Perplan?",
            "id_tipo_resposta_pergunta": 7,
            "tipo_resposta_pergunta": {
              "id_tipo_resposta_pergunta": 7,
              "nome": "Simples seleção"
            },
            "options": [
              {
                "label": "AINDA NÃO CONHECIA",
                "value": "AINDA NÃO CONHECIA"
              },
              {
                "label": "CONHECIA, MAS NÃO MUITO",
                "value": "CONHECIA, MAS NÃO MUITO"
              },
              {
                "label": "CONHEÇO E CONFIO NA MARCA",
                "value": "CONHEÇO E CONFIO NA MARCA"
              }
            ],
            "criado_por": 1,
            "oculto": 0,
            "ativo": "1"
          },
          "criado_por": 1,
          "oculto": 0,
          "ativo": "1"
        },
        {
          "id_questionario": 1,
          "id_pergunta": 3,
          "pergunta": {
            "id_pergunta": 3,
            "enunciado": "Como foi a sua experiência em nosso estande?",
            "id_tipo_resposta_pergunta": 7,
            "tipo_resposta_pergunta": {
              "id_tipo_resposta_pergunta": 7,
              "nome": "Simples seleção"
            },
            "options": [
              {
                "label": "NÃO GOSTEI",
                "value": "NÃO GOSTEI"
              },
              {
                "label": "GOSTEI PARCIALMENTE",
                "value": "GOSTEI PARCIALMENTE"
              },
              {
                "label": "BASTANTE SATISFATÓRIA",
                "value": "BASTANTE SATISFATÓRIA"
              }
            ],
            "criado_por": 1,
            "oculto": 0,
            "ativo": "1"
          },
          "criado_por": 1,
          "oculto": 0,
          "ativo": "1"
        },
        {
          "id_questionario": 1,
          "id_pergunta": 4,
          "pergunta": {
            "id_pergunta": 4,
            "enunciado": "O que mais gostou do atendimento do corretor?\n(pode assinalar mais de uma alternativa)",
            "id_tipo_resposta_pergunta": 8,
            "tipo_resposta_pergunta": {
              "id_tipo_resposta_pergunta": 8,
              "nome": "Multipla seleção"
            },
            "options": [
              {
                "label": "A ATENÇÃO E SIMPATIA",
                "value": "A ATENÇÃO E SIMPATIA"
              },
              {
                "label": "O PREPARO EM IDENTIFICAR QUAL ERA A MINHA NECESSIDADE",
                "value": "O PREPARO EM IDENTIFICAR QUAL ERA A MINHA NECESSIDADE"
              },
              {
                "label": "O DOMÍNIO E CLAREZA NAS INFORMAÇÕES PASSADAS",
                "value": "O DOMÍNIO E CLAREZA NAS INFORMAÇÕES PASSADAS"
              },
              {
                "label": "A APARÊNCIA E ORGANIZAÇÃO",
                "value": "A APARÊNCIA E ORGANIZAÇÃO"
              },
              {
                "label": "NÃO GOSTEI DO ATENDIMENTO DO CORRETOR",
                "value": "NÃO GOSTEI DO ATENDIMENTO DO CORRETOR"
              },
              {
                "label": "PREFIRO NÃO RESPONDER",
                "value": "PREFIRO NÃO RESPONDER"
              }
            ],
            "criado_por": 1,
            "oculto": 0,
            "ativo": "1"
          },
          "criado_por": 1,
          "oculto": 0,
          "ativo": "1"
        },
        {
          "id_questionario": 1,
          "id_pergunta": 5,
          "pergunta": {
            "id_pergunta": 5,
            "enunciado": "Algo poderia ter sido melhor no atendimento do Corretor?",
            "id_tipo_resposta_pergunta": 8,
            "tipo_resposta_pergunta": {
              "id_tipo_resposta_pergunta": 8,
              "nome": "Multipla seleção"
            },
            "options": [
              {
                "label": "A ATENÇÃO E SIMPATIA",
                "value": "A ATENÇÃO E SIMPATIA"
              },
              {
                "label": "O PREPARO EM IDENTIFICAR QUAL ERA A MINHA NECESSIDADE",
                "value": "O PREPARO EM IDENTIFICAR QUAL ERA A MINHA NECESSIDADE"
              },
              {
                "label": "O DOMÍNIO E CLAREZA NAS INFORMAÇÕES PASSADAS",
                "value": "O DOMÍNIO E CLAREZA NAS INFORMAÇÕES PASSADAS"
              },
              {
                "label": "A APARÊNCIA E ORGANIZAÇÃO",
                "value": "A APARÊNCIA E ORGANIZAÇÃO"
              }
            ],
            "criado_por": 1,
            "oculto": 0,
            "ativo": "1"
          },
          "criado_por": 1,
          "oculto": 0,
          "ativo": "1"
        },
        {
          "id_questionario": 1,
          "id_pergunta": 6,
          "pergunta": {
            "id_pergunta": 6,
            "enunciado": "O que mais gostou neste empreendimento?\n(pode assinalar mais de uma alternativa)",
            "id_tipo_resposta_pergunta": 8,
            "tipo_resposta_pergunta": {
              "id_tipo_resposta_pergunta": 8,
              "nome": "Multipla seleção"
            },
            "options": [
              {
                "label": "LOCALIZAÇÃO",
                "value": "LOCALIZAÇÃO"
              },
              {
                "label": "PREÇO",
                "value": "PREÇO"
              },
              {
                "label": "CONDIÇÕES DE PAGAMENTO",
                "value": "CONDIÇÕES DE PAGAMENTO"
              },
              {
                "label": "ÁREA DE LAZER",
                "value": "ÁREA DE LAZER"
              },
              {
                "label": "PLANTA",
                "value": "PLANTA"
              },
              {
                "label": "PRAZO DE ENTREGA",
                "value": "PRAZO DE ENTREGA"
              },
              {
                "label": "METRAGEM",
                "value": "METRAGEM"
              }
            ],
            "criado_por": 1,
            "oculto": 0,
            "ativo": "1"
          },
          "criado_por": 1,
          "oculto": 0,
          "ativo": "1"
        },
        {
          "id_questionario": 1,
          "id_pergunta": 7,
          "pergunta": {
            "id_pergunta": 7,
            "enunciado": "Algo não lhe agradou tanto?\n(pode assinalar mais de uma alternativa)",
            "id_tipo_resposta_pergunta": 8,
            "tipo_resposta_pergunta": {
              "id_tipo_resposta_pergunta": 8,
              "nome": "Multipla seleção"
            },
            "options": [
              {
                "label": "LOCALIZAÇÃO",
                "value": "LOCALIZAÇÃO"
              },
              {
                "label": "PREÇO",
                "value": "PREÇO"
              },
              {
                "label": "CONDIÇÕES DE PAGAMENTO",
                "value": "CONDIÇÕES DE PAGAMENTO"
              },
              {
                "label": "ÁREA DE LAZER",
                "value": "ÁREA DE LAZER"
              },
              {
                "label": "PLANTA",
                "value": "PLANTA"
              },
              {
                "label": "PRAZO DE ENTREGA",
                "value": "PRAZO DE ENTREGA"
              },
              {
                "label": "METRAGEM (Nos conte na última pergunta, por favor, qual metragem procura)",
                "value": "METRAGEM (Nos conte na última pergunta, por favor, qual metragem procura)"
              },
              {
                "label": "VAGA DE GARAGEM",
                "value": "VAGA DE GARAGEM"
              }
            ],
            "criado_por": 1,
            "oculto": 0,
            "ativo": "1"
          },
          "criado_por": 1,
          "oculto": 0,
          "ativo": "1"
        },
        {
          "id_questionario": 1,
          "id_pergunta": 8,
          "pergunta": {
            "id_pergunta": 8,
            "enunciado": "Pretende Realizar uma compra em breve?",
            "id_tipo_resposta_pergunta": 7,
            "tipo_resposta_pergunta": {
              "id_tipo_resposta_pergunta": 7,
              "nome": "Simples seleção"
            },
            "options": [
              {
                "label": "SIM, NOS PRÓXIMOS 45 DIAS",
                "value": "SIM, NOS PRÓXIMOS 45 DIAS"
              },
              {
                "label": "SIM, NOS PRÓXIMOS 6 MESES",
                "value": "SIM, NOS PRÓXIMOS 6 MESES"
              },
              {
                "label": "SIM, MAS SEM DATA PREVISTA",
                "value": "SIM, MAS SEM DATA PREVISTA"
              },
              {
                "label": "AINDA NÃO SEI SE IREI COMPRAR UM IMÓVEL/LOTE",
                "value": "AINDA NÃO SEI SE IREI COMPRAR UM IMÓVEL/LOTE"
              },
              {
                "label": "PREFIRO NÃO RESPONDER",
                "value": "PREFIRO NÃO RESPONDER"
              }
            ],
            "criado_por": 1,
            "oculto": 0,
            "ativo": "1"
          },
          "criado_por": 1,
          "oculto": 0,
          "ativo": "1"
        },
        {
          "id_questionario": 1,
          "id_pergunta": 11,
          "pergunta": {
            "id_pergunta": 11,
            "enunciado": "Se tiver qualquer comentário ou sugestão, nos conte aqui:",
            "id_tipo_resposta_pergunta": 1,
            "tipo_resposta_pergunta": {
              "id_tipo_resposta_pergunta": 1,
              "nome": "Texto"
            },
            "options": [],
            "criado_por": 1,
            "oculto": 0,
            "ativo": "1"
          },
          "criado_por": 1,
          "oculto": 0,
          "ativo": "1"
        }
      ],
      "criado_por": 1,
      "oculto": 0,
      "ativo": "1"
    }
  );

  return (
    <QuestionarioContext.Provider value={{
      questionario,
      setQuestionario,
      questionarioRespondido,
      setQuestionarioRespondido
    }}>
      {children}
    </QuestionarioContext.Provider>
  );
}
