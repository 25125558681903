import React, { createContext, ReactElement } from "react";
import { QuestionarioProvider } from "./QuestionarioContext/QuestionarioContext";

interface AppContextProps {

}

export const AppContext = createContext({} as AppContextProps);

export function AppProvider({ children }: {
    children: ReactElement;
}) {

    return (
        <AppContext.Provider value={{}}>
            <QuestionarioProvider>
                {children}
            </QuestionarioProvider>
        </AppContext.Provider>
    );
}
