import React, { useContext } from "react";
import "./index.css";
import { FormProvider, useForm } from "react-hook-form";
import { Alert, Button, Form, Row } from "react-bootstrap";
import { QuestionarioContext } from "../../context/QuestionarioContext/QuestionarioContext";
import { TipoRespostaEnum } from "../types/questionario";
import { apenasNumeros } from "../../helpers/Mascaras";
import { PerguntaItem } from "./PerguntaItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";

export function Questionario() {
  const { questionario, setQuestionarioRespondido } = useContext(QuestionarioContext);
  const metodos = useForm();

  const formatarResposta = (data: any) => {
    Object.keys(data).map((key) => {
      let pergunta = questionario.perguntas?.find(
        (pergunta) => pergunta.id_pergunta === parseInt(key)
      );
      switch (pergunta?.pergunta.id_tipo_resposta_pergunta) {
        case TipoRespostaEnum.CEP:
        case TipoRespostaEnum.CPF:
        case TipoRespostaEnum.TELEFONE:
        case TipoRespostaEnum.MOEDA:
          data[key] = apenasNumeros(data[key]);
          break;
        default:
          break;
      }
    });
    return data;
  };

  const handleOnSubmit = (data: any) => {
    setQuestionarioRespondido(true);
    debugger
    // let respostasFormatadas = formatarResposta(data);
    // trackPromise(
    //   QuestionarioService.storeRespostasQuestionario(
    //     oportunidade.id_oportunidade,
    //     questionario.id_questionario,
    //     respostasFormatadas
    //   )
    // )
    //   .then((response: any) => {
    //     atualizarRespostas(response.data);
    //     toastSucesso("Respostas salvas com sucesso");
    //   })
    //   .catch((error) => {
    //     Swal.fire({
    //       titleText: "Ooops...",
    //       text:
    //         "Ocorreu um erro ao tentar salvar as respostas: " +
    //         error?.response?.data ?? error,
    //       icon: "error",
    //     });
    //   });
  };

  return (
    <FormProvider {...metodos}>
      <Form
        onSubmit={metodos.handleSubmit(handleOnSubmit)}
        className="form-questionario"
      >
        {questionario.perguntas?.length ? (
          <>
            {questionario.perguntas?.map((pergunta, index) => {
              return (
                <PerguntaItem key={index} index={index} pergunta={pergunta} />
              );
            })}
            <Row className="btn-enviar-questionario">
              <Button variant="success" type="submit">
                Enviar Resposta
                <FontAwesomeIcon icon={faPaperPlane} />
              </Button>
            </Row>
          </>
        ) : (
          <Alert variant="warning">
            Nenhuma pergunta vinculada nesse questionário
          </Alert>
        )}
      </Form>
    </FormProvider>
  );
}
